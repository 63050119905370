import React, { Component } from 'react';

export default class Footer extends Component {

  render() {
    return (
      <footer>
        <section>
        <h1>Let&apos;s Connect</h1>
        <div className="snslink">
          <a href="https://github.com/backy22" target="blank">
            <svg className="snsicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.42 33.57"><defs></defs><title>Asset 19</title><path className="cls-1" d="M17.21,0a17.21,17.21,0,0,0-5.44,33.54c.86.16,1.13-.35,1.13-.81s0-1.55,0-3c-4.78,1-5.78-2.27-5.78-2.27A4.55,4.55,0,0,0,5.21,25c-1.56-1.07.12-1,.12-1A3.61,3.61,0,0,1,8,25.69a3.67,3.67,0,0,0,5,1.43,3.66,3.66,0,0,1,1.09-2.3c-3.82-.44-7.84-1.91-7.84-8.51A6.67,6.67,0,0,1,8,11.69a6.14,6.14,0,0,1,.17-4.55S9.62,6.68,12.9,8.9a16.3,16.3,0,0,1,8.62,0c3.29-2.22,4.73-1.76,4.73-1.76a6.14,6.14,0,0,1,.17,4.55,6.67,6.67,0,0,1,1.77,4.62c0,6.61-4,8.07-7.86,8.49A4.08,4.08,0,0,1,21.51,28v4.77c0,.46.28,1,1.15.8A17.21,17.21,0,0,0,17.21,0Z"/></svg>
          </a>
          {/* <a href="https://codepen.io/aya-tsubakino" target="blank">
            <svg className="snsicon" xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 34.8 34.5" version="1.1" viewBox="0 0 34.8 34.5" xmlSpace="preserve">
              <path d="M5.4 13.9v5.7c.9-.5 2.3-1.4 4.4-2.7l-4.4-3z" className="cls-1"></path>
              <path d="M6.2 21.2L16.6 28.5 16.6 21.6 11.4 18z" className="cls-1"></path>
              <path d="M16.6 12.9V6L6.2 12.3l5.3 3.7c1.4-.8 3.1-1.9 5.1-3.1z" className="cls-1"></path>
              <path d="M28.7 12.3L18.3 6 18.3 12.9 23.4 16z" className="cls-1"></path>
              <path d="M17.4 14.4L13 17.1 17.4 20.2 21.9 17.1z" className="cls-1"></path>
              <path d="M18.3 21.6L18.3 28.5 28.7 21.2 23.5 18z" className="cls-1"></path>
              <path d="M17.4 0C7.9 0 .1 7.7.1 17.3s7.7 17.3 17.3 17.3 17.3-7.7 17.3-17.3S27 0 17.4 0zm.6 30.8c-.3.3-.8.3-1.1 0-4.3-3.4-13.2-8.6-13.2-9.6 0-8.5-.2-9.1.2-9.5.4-.5 3.2-1.9 13.1-8 .8-.5.7.1 13.7 7.8.3.2.4.4.4.7v8.9c.1.9-.2.6-13.1 9.7z" className="cls-1"></path>
              <path d="M29.4 19.7L29.4 13.9 25 17z" className="cls-1"></path>
            </svg>
          </a> */}
          {/* <a href="https://www.instagram.com/ayatsubakino/" target="blank">
            <svg className="snsicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.51 32.51"><defs></defs><title>Asset 20</title><path className="cls-1" d="M21.28,12.25h.83a.65.65,0,0,0,.64-.68V10a.67.67,0,0,0-.68-.69H20.5a.67.67,0,0,0-.68.69v1.56a.4.4,0,0,0,.05.24.62.62,0,0,0,.63.44Zm-5,.69a3.37,3.37,0,1,0,3.32,3.46A3.3,3.3,0,0,0,16.26,12.94Zm-6.5,1.75h0v7.38a.65.65,0,0,0,.64.63H22a.64.64,0,0,0,.63-.63V14.69H21.09a4.81,4.81,0,0,1,.19,2.2,5.05,5.05,0,0,1-.73,2.05A4.6,4.6,0,0,1,19,20.5a5.19,5.19,0,0,1-5.37.25,5.26,5.26,0,0,1-2-2,5.08,5.08,0,0,1-.39-4.06Zm13.09,9.82a2.42,2.42,0,0,0,.24-.05,1.81,1.81,0,0,0,1.37-1.37.4.4,0,0,1,.05-.24V9.67a.3.3,0,0,0-.05-.2,1.78,1.78,0,0,0-1.52-1.42H9.62c-.1,0-.15,0-.25,0A1.71,1.71,0,0,0,8,9.62V22.9c0,.09,0,.14,0,.24a1.79,1.79,0,0,0,1.52,1.37H22.85Zm-6.59,8A16.26,16.26,0,1,1,32.51,16.26,16.25,16.25,0,0,1,16.26,32.51Z"/></svg>
          </a> */}
          <a href="https://www.linkedin.com/in/ayatsubakino/" target="blank">
            <svg className="snsicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.41 32.41"><defs></defs><title>Asset 21</title><path className="cls-1" d="M25.34,23.87V17.53c0-3.42-1.81-5-4.25-5a3.7,3.7,0,0,0-3.32,1.8V12.79H14.11c.05,1,0,11,0,11h3.66V17.67a2.27,2.27,0,0,1,.1-.88,2,2,0,0,1,1.9-1.36c1.32,0,1.86,1,1.86,2.49v5.9l3.71.05ZM10.25,11.28a1.93,1.93,0,0,0,2.1-1.91,1.89,1.89,0,0,0-2-1.9,1.93,1.93,0,0,0-2.1,1.9,1.89,1.89,0,0,0,2.05,1.91Zm6,21.13a16.21,16.21,0,1,1,16.2-16.2A16.19,16.19,0,0,1,16.21,32.41Zm-4.15-8.54V12.79H8.4v11h3.66Z"/></svg>
          </a>
        </div>
        </section>
        <div className="copyright">
          <small>Copyright 2023</small>
        </div>
      </footer>
    );
  }
}
